import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'

const ThreadProducts = () => {
    return (
        <>
            <Navbar />
            <Footer />
        </>
    )
}

export default ThreadProducts