import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import Offer from '../../components/offer/Offer'

const index = () => {
    return (
        <>
            <Navbar />
            <Offer />
            <Footer />
        </>
    )
}

export default index