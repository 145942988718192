import React, { useState, useContext, useEffect } from "react";

//importing components
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import email from "../../assets/email.png";
import password from "../../assets/password.png";
import { useLocation } from "react-router-dom";
import loinone from "../../assets/l1.png"

//importing css
import "./logIn.scss";
//import navigate

import { useNavigate } from "react-router-dom";

//import Link
import { Link } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { CartContext } from "../../context/CartContext";
import { TransContext } from "../../context/TransContext";
import { Col, Row } from "antd";

export default function Login() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const [loginLoading, setLoginLoading] = useState(false)
  const { loading, error, dispatch } = useContext(AuthContext);

  const [Error, setError] = useState(null);
  //fetching cart object and destructuring it
  const cartObj = useContext(CartContext);
  const cart = cartObj.cart;
  const cartErr = cartObj.error;
  const cartDispatch = cartObj.dispatch;
  const cartLoading = cartObj.loading;

  //fetching trans object and destructuring it
  const TransObj = useContext(TransContext);
  const transDispatch = TransObj.dispatch;
  const priceDetails = TransObj.priceDetails;
  let { totalPrice, totalSaving } = priceDetails;

  const [credantial, setCredantial] = useState({
    email: undefined,
    password: undefined,
  });

  const navigate = useNavigate();

  const handlechange = (e) => {
    setCredantial((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  //pattern for email validation
  //const pattern= /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/

  // const setCookie=async ()=>{
  //      try{
  //          axios.post(`${baseURL}/auth/set-cookie`,{},{withCredentials:true})
  //      }catch(e){
  //          console.log(e)
  //      }
  // }

  // const getCookie=async ()=>{
  //   try{
  //      axios.post(`${baseURL}/auth/get-cookie`,{withCredentials:true})
  //   }catch(e){
  //     console.log(e)
  //   }
  // }

  const handleLogin = async (e) => {
    setLoginLoading(true)
    e.preventDefault();
    const localcartItems = cart;

    dispatch({ type: "LOGIN_START" });
    cartDispatch({ type: "CART_START" });

    try {
      const res = await axios.post(`${baseURL}/auth/login`, credantial, {
        withCredentials: true,
      });
      const { token, details } = res.data;
      localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("user", JSON.stringify(details));
      const dbcartitems = res.data.details.cartItems;

      dispatch({ type: "LOGIN_SUCCESS", payload: res.data.details });

      let cartitemslist = [];
      //newitemlist is array of that cart items which are not presant in localstorage
      let newitemlist = [];
      if (
        localcartItems !== null &&
        localcartItems.length !== 0 &&
        dbcartitems.length !== 0
      ) {
        cartitemslist = cartitemslist.concat(localcartItems);

        dbcartitems.forEach((dbitem) => {
          let isavailable = false;
          cartitemslist.forEach((citem) => {
            if (citem.productId === dbitem.productId) {
              isavailable = true;
            }
          });
          if (isavailable === false) {
            cartitemslist.push(dbitem);
            newitemlist.push({ productId: dbitem.productId, qnt: dbitem.qnt });
          }
        });
      } else if (
        (localcartItems === null || localcartItems.length === 0) &&
        dbcartitems.length !== 0
      ) {
        cartitemslist = cartitemslist.concat(dbcartitems);
        dbcartitems.map((dbitem) => {
          newitemlist.push({ productId: dbitem.productId, qnt: dbitem.qnt });
        });
      } else if (
        localcartItems !== null &&
        localcartItems.length !== 0 &&
        dbcartitems.length === 0
      ) {
        cartitemslist = cartitemslist.concat(localcartItems);
      }

      if (cartitemslist.lenght !== 0) {
        try {
          await axios.post(
            `${baseURL}/user/cart/${res.data.details._id}`,
            cartitemslist
          );
          cartDispatch({ type: "CART_CHANGE", payload: cartitemslist });

          if (newitemlist.length !== 0) {
            let cObj = {
              cartitems: newitemlist,
            };
            try {
              let priceObj = await axios.post(`${baseURL}/product/price`, cObj);

              totalPrice += priceObj.data.totalPrice;
              totalSaving += priceObj.data.totalSaving;

              transDispatch({
                type: "TRANS_PRICE",
                payload: { totalPrice, totalSaving },
              });
            } catch (err) {
              setLoginLoading(false)
              setError(err.message);
            }
          }
        } catch (err) {
          setLoginLoading(false)
          cartDispatch({ type: "CART_FAILURE", payload: err.response.data });
        }
      }
      setLoginLoading(false)
      navigate("/");
    } catch (err) {
      setLoginLoading(false)
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
    }
  };
  return (
    <div>
      <Navbar></Navbar>
      <Row className="flex justify-center items-center mt-[3.90rem] p-8 md:p-16 bg-gray-50">
        <Col
          span={24}
          xs={24}
          sm={24}
          md={20}  // Adjusted width for medium screens
          lg={10}  // Adjusted width for large screens
          xl={8}   // Adjusted width for extra-large screens
          xxl={6}  // Adjusted width for extra-extra-large screens
          className="p-8 bg-white border border-gray-200 shadow-md space-y-6"
        >
          <div className="space-y-4">
            <span className="text-green-600">{location.state}</span>
            <h2 className="text-3xl font-semibold text-center text-gray-800">Login</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-600">Email</label>
                <div className="flex items-center border border-gray-300 rounded focus-within:ring focus-within:ring-gray-300">
                  <img src={email} alt="" className="w-6 h-6 m-2 filter invert contrast-0" />
                  <div className="w-px h-full bg-gray-300"></div>
                  <input
                    type="email"
                    onChange={handlechange}
                    id="email"
                    className="w-full px-3 py-2 outline-none"
                    placeholder="Enter your email address"
                    required
                  />
                </div>
              </div>

              {/* Password Input */}
              <div>
                <label className="block text-sm font-medium text-gray-600">Password</label>
                <div className="flex items-center border border-gray-300 rounded focus-within:ring focus-within:ring-gray-300">
                  <img src={password} alt="" className="w-6 h-6 m-2 filter invert contrast-0" />
                  <div className="w-px h-full bg-gray-300"></div>
                  <input
                    type="password"
                    onChange={handlechange}
                    id="password"
                    className="w-full px-3 py-2 outline-none"
                    placeholder="Enter Your Password"
                    required
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={loginLoading}
              onClick={handleLogin}
              className="w-full px-4 py-2 mt-4 text-white bg-gray-600 rounded hover:bg-gray-700 focus:outline-none"
            >
              Log In
            </button>

            <div className="flex justify-center items-center text-gray-500">
              <span>New Customer?</span>
              <Link to="/register" className="ml-2 text-gray-600 hover:text-gray-800 underline">
                Register
              </Link>
            </div>

            {/* Error messages */}
            {error && <span className="block text-red-500">{error.message}</span>}
            {cartErr && <span className="block text-red-500">{cartErr.message}</span>}
            {Error && <span className="block text-red-500">{Error}</span>}
          </div>
        </Col>

        {/* <Col
          span={24}
          xs={24}
          sm={24}
          md={24}
          lg={10}
          xl={12}
          xxl={12}
          className="hidden lg:block"
        >
          <img src={loinone} alt="" className="object-cover w-full h-full" />
        </Col> */}
      </Row>
      <Footer></Footer>
    </div>
  );
}
