import React, { useEffect } from 'react'
import { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import useFetch from '../../hooks/useFetch'
import { TransContext } from '../../context/TransContext'
import axiosInstance from '../../axiosInstance'

export default function AddressWindow({ setAddressWindow, setPayWindow, setAddressCompleted }) {
  const baseURL = process.env.REACT_APP_API_BASE_URL
  const userObj = useContext(AuthContext)
  const user = userObj.user
  const userDispatch = userObj.dispatch
  const { dispatch } = useContext(TransContext)
  const { data, loading } = useFetch(`/user/getaddress/${user._id}`)
  const [addressAvailable, setAddressAvailable] = useState(!(Object.keys(data).length === 0))
  const [addressObj, setAddressObj] = useState({ addresstype: "home", state: "Florida", country: "US" })
  const [home, setHome] = useState(true)
  const [work, setWork] = useState(false)
  const [error, setError] = useState(null)

  // Regex patterns for zip codes by country
  const zipCodePatterns = {
    US: /^[0-9]{5}(-[0-9]{4})?$/,            // United States (5 digits, optionally 4 more)
    UK: /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/, // United Kingdom
    AU: /^[0-9]{4}$/,                        // Australia (4 digits)
    NZ: /^[0-9]{4}$/,                        // New Zealand (4 digits)
    DE: /^[0-9]{5}$/,                        // Germany (5 digits)
    EU: /^[0-9A-Za-z -]+$/                    // Europe (varies by country, so this allows a mix)
  };

  useEffect(() => {
    if (loading === false) {
      setAddressAvailable(!(Object.keys(data).length === 0))
      setAddressObj((prev) => ({ ...prev, ...data.address }))
      setHome((!(Object.keys(data).length === 0)) ? ((data.address.addresstype === "home") ? (true) : (false)) : (true))
      setWork((!(Object.keys(data).length === 0)) ? ((data.address.addresstype === "work") ? (true) : (false)) : (false))
    }
  }, [loading])

  const handleChange = (e) => {
    const { id, value } = e.target

    if (id === 'work') {
      setHome(false)
      setWork(true)
      setAddressObj((prev) => ({ ...prev, ["addresstype"]: "work" }))
    } else if (id === 'home') {
      setWork(false)
      setHome(true)
      setAddressObj((prev) => ({ ...prev, ["addresstype"]: "home" }))
    } else if (id === 'country') {
      // Reset zip code on country change
      setAddressObj((prev) => ({ ...prev, country: value, zipCode: '' }))
      setError(null) // Clear error when country changes
    } else {
      setAddressObj((prev) => ({ ...prev, [id]: value }))
    }
  }

  const validateZipCode = () => {
    const { zipCode, country } = addressObj
    const pattern = zipCodePatterns[country]
    if (pattern && !pattern.test(zipCode)) {
      setError("Please enter a valid zip code for the selected country.")
      return false
    }
    return true
  }

  const handleSubmit = () => {
    if (!validateZipCode()) return

    if (addressObj.addressLine1.length <= 2 || addressObj.addressLine1 === "") {
      setError("Please enter valid address...")
    } else if (addressObj.city.length <= 2 || addressObj.city === "") {
      setError("Please enter valid city name..!")
    } else if (addressObj.state.length <= 2 || addressObj.state === "") {
      setError("Please enter valid state name..!")
    } else {
      setError(null)
      saveAddressChanges()
    }
  }

  const saveAddressChanges = async () => {
    try {
      const res = await axiosInstance.post(`${baseURL}/user/updateaddress/${user._id}`, addressObj, { withCredentials: true })
      userDispatch({ type: "LOGIN_SUCCESS", payload: res.data })
      changeWindow()
    } catch (err) {
      setError("There is something wrong...!")
    }
  }

  const changeWindow = () => {
    setAddressCompleted(true)
    setAddressWindow(false)
    setPayWindow(true)
  }

  return (
    loading ? (
      "Loading please wait..."
    ) : (
      <div className='infoWindow addresswide'>
        <div className='addressHead'>
          <h4>Add New Address</h4>
          <span>*Mandatory Fields</span>
        </div>
        <form className='addressForm'>
          <div className='inputBoxContainer'>
            <div className='inputBox'>
              <span>Customer Name</span>
              <input type='text' value={`${user.first_name} ${user.last_name}`} readOnly />
            </div>
            <div className='inputBox'>
              <span>Mobile No</span>
              <input type='text' value={user.mobileno} readOnly />
            </div>
          </div>

          <div className='inputBoxContainer'>
            <div className='inputBox'>
              <span>Address Line 1 *</span>
              <input
                id='addressLine1'
                type='text'
                defaultValue={addressAvailable ? addressObj.addressLine1 : ""}
                onChange={handleChange}
                placeholder='e.g., 123 Main St'
                required
              />
            </div>
          </div>

          <div className='inputBoxContainer'>
            <div className='inputBox'>
              <span>Address Line 2</span>
              <input
                id='addressLine2'
                type='text'
                defaultValue={addressAvailable ? addressObj.addressLine2 : ""}
                onChange={handleChange}
                placeholder='Apt, suite, unit, building, floor, etc.'
              />
            </div>
          </div>

          <div className='inputBoxContainer'>
            <div className='inputBox'>
              <span>Address Line 3</span>
              <input
                id='addressLine3'
                type='text'
                defaultValue={addressAvailable ? addressObj.addressLine3 : ""}
                onChange={handleChange}
                placeholder='Optional additional address info'
              />
            </div>
            <div className='inputBox'>
              <span>Address Line 4</span>
              <input
                id='addressLine4'
                type='text'
                defaultValue={addressAvailable ? addressObj.addressLine4 : ""}
                onChange={handleChange}
                placeholder='Optional additional address info'
              />
            </div>
          </div>

          <div className='inputBoxContainer'>
            <div className='inputBox'>
              <span>City/Town *</span>
              <input
                id='city'
                type='text'
                defaultValue={addressAvailable ? addressObj.city : ""}
                onChange={handleChange}
                placeholder='e.g., Miami'
                required
              />
            </div>
            <div className='inputBox'>
              <span>Zip Code *</span>
              <input
                id='zipCode'
                type='text'
                defaultValue={addressAvailable ? addressObj.zipCode : ""}
                onChange={handleChange}
                placeholder={zipCodePatterns[addressObj.country || "US"] ? "e.g., 32003" : "Enter valid postal code"}
                required
              />
            </div>
          </div>

          <div className='inputBoxContainer'>
            <div className='inputBox'>
              <span>State *</span>
              <input
                id='state'
                type='text'
                defaultValue={addressAvailable ? addressObj.state : ""}
                onChange={handleChange}
                placeholder='e.g., Florida'
                required
              />
            </div>
            <div className='inputBox'>
              <span>Country *</span>
              <select
                name="country"
                id="country"
                onChange={handleChange}
                value={addressAvailable ? addressObj.country : ""}
              >
                <option value="US">United States</option>
                <option value="EU">Europe</option>
                <option value="UK">United Kingdom</option>
                <option value="AU">Australia</option>
                <option value="NZ">New Zealand</option>
                <option value="DE">Germany</option>
              </select>
            </div>
          </div>

          <div className='checkBoxContainer'>
            <span>Select address type *</span>
            <div className='inputBoxContainer'>
              <div className='checkBox'>
                <input id='home' type='checkbox' checked={home} onChange={handleChange} />
                <span className='checkSpan'>Home</span>
              </div>
              <div className='checkBox'>
                <input id='work' type='checkbox' checked={work} onChange={handleChange} />
                <span className='checkSpan'>Work</span>
              </div>
            </div>
          </div>
        </form>

        <button
          className='addressContinueBtn'
          onClick={handleSubmit}
          disabled={Object.keys(addressObj).length >= 7 ? false : true}
        >
          Continue
        </button>
        <span className='errMsg'>{error}</span>
      </div>
    )
  )
}
