import React, { useEffect } from 'react'

//importing css
import './about.css'


//importing components
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'

//importing motion for animation
import { motion } from 'framer-motion'

//importing images
import Person from '../../assets/mitbhuva.png'
import Tree from '../../assets/tree_image.png'
// import about_us_logo from '../../assets/about-us2.avif'
import about_us_logo from '../../assets/website slideshow-04-1.jpg'

export default function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const fadeInUp = {
        hidden: { opacity: 0, y: 50 },
        show: { opacity: 1, y: 0 }
    }
    const transition = {
        duration: .5,
        staggerChildren: 0.2
    }
    const sectionVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 }
    };
    return (
        <>
            <Navbar></Navbar>
            <div className='about'>
                <div className="headerImageContainer">
                    <img className="headerImg" src={about_us_logo} alt={`FAQ}`} />
                    <div className='tint flex flex-col'>
                        <h3 className='text-white font-thin text-3xl md:text-6xl'>About us</h3>
                        <div className='text-white tracking-[4px] mt-3 text-center text-sm md:text-lg'>QUICK ANSWERS FOR YOUR PEACE OF MIND</div>
                    </div>
                </div>
                <div className="bg-gray-100 text-gray-800 min-h-screen p-2">
                    <div className="max-w-4xl mx-auto py-8">
                        <section className="text-center mb-12">
                            <h2 className="text-3xl font-bold text-gray-700">The Beauty of Handcrafted Art</h2>
                            <p className="mt-4 text-lg leading-relaxed">
                                Hand-woven and hand-made products represent the pinnacle of an artisan’s craft.
                                Each piece reflects the beauty of traditional techniques combined with artistic
                                dedication.
                            </p>
                            <p className="mt-4 text-lg leading-relaxed">
                                Let’s support artisans who pour their skill and soul into these creations, keeping
                                ancient crafts alive for future generations.
                            </p>
                            <div className="mt-6 italic text-gray-600">
                                - Concept by Neelam Shukla, Designer and Advocate for Sustainable Crafts
                            </div>
                            <p className="mt-4 text-lg leading-relaxed">
                                Neelam believes in the preservation of handcraft techniques developed centuries ago,
                                blending modern and traditional methods to create timeless pieces. Your support helps
                                transform the lives of artisans, sustaining their invaluable contributions to art.
                            </p>
                        </section>
                        {/* Contact Information */}
                        <motion.section
                            initial='hidden'
                            whileInView='show'
                            variants={fadeInUp}
                            transition={transition}
                            className="text-center mb-8"
                        >
                            <h2 className="text-3xl font-bold">Contact us</h2>
                            {/* <p className="mt-4">Mon – SAT | 10:00 AM – 6:00 PM (IST)</p> */}
                            <p className="mt-4">
                                <a href="mailto:shop@kashmirloom.com" className="text-blue-600 hover:underline">
                                    weavesfabrics12@gmail.com
                                </a>
                            </p>
                        </motion.section>
                        {/* Shipping Information */}
                        <motion.section
                            className="bg-white shadow-md rounded-lg p-6 mb-8"
                            initial='hidden'
                            whileInView='show'
                            variants={fadeInUp}
                            transition={transition}
                        >
                            <h3 className="text-2xl font-semibold mb-4">Shipping Information</h3>
                            <p>
                                Each product has a different lead time, please check the product details for shipping lead time.
                                International orders are usually delivered within 10-15 business days and domestic orders within
                                7-10 business days.
                            </p>
                            {/* <p className="mt-4">For all orders within India, we offer free shipping.</p> */}
                            {/* <p>$40 flat shipping for all international orders.</p> */}
                            <p className="mt-4">
                                We use the most reliable courier services for deliveries, including FedEx, UPS, and DHL.
                            </p>
                        </motion.section>

                        {/* Payment Information */}
                        <motion.section
                            className="bg-white shadow-md rounded-lg p-6 mb-8"
                            initial='hidden'
                            whileInView='show'
                            variants={fadeInUp}
                            transition={transition}
                        >
                            <h3 className="text-2xl font-semibold mb-4">Payment</h3>
                            <p>
                                For shipping to India, we accept all major debit & credit cards (including Mastercard, Visa &
                                American Express) and provide Net Banking options.
                            </p>
                            <p>
                                For all international transactions, we accept major Credit Cards (including Mastercard, Visa &
                                American Express).
                            </p>
                        </motion.section>

                        {/* Refund Policy */}
                        <motion.section
                            className="bg-white shadow-md rounded-lg p-6 mb-8"
                            initial='hidden'
                            whileInView='show'
                            variants={fadeInUp}
                            transition={transition}
                        >
                            <h3 className="text-2xl font-semibold mb-4">Refund Policy</h3>
                            <p>
                                Weaves Fabrics takes great pride in our customer service and delivering products that meet
                                high-quality standards.
                            </p>
                            <p>
                                If you receive a damaged or defective product, we will provide a replacement or full refund
                                within 2 days of receiving the product.
                            </p>
                            <p className="mt-4">
                                To return an item, email us at{' '}
                                <a href="mailto:shop@kashmirloom.com" className="text-blue-600 hover:underline">
                                    weavesfabrics12@gmail.com
                                </a>{' '}
                                with your order number and details of the transaction.
                            </p>
                        </motion.section>

                        {/* Disclaimer */}
                        <motion.section
                            className="text-sm text-gray-600 mt-8 text-center"
                            initial='hidden'
                            whileInView='show'
                            variants={fadeInUp}
                            transition={transition}
                        >
                            <p>All policies are subject to change without prior notice. In case of any conflict, Terms of Service Policy would prevail.</p>
                            {/* <div className="flex justify-center space-x-4 mt-4">
                                <a href="/privacy-policy" className="hover:underline">View Privacy Policy</a>
                                <a href="/terms-of-service" className="hover:underline">View Terms of Service</a>
                            </div> */}
                        </motion.section>
                    </div>
                </div>

            </div>
            <Footer></Footer>
        </>
    )
}

