import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import axiosInstance from '../../axiosInstance';

export default function SuccessPage() {
    const [searchParams] = useSearchParams();
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // Extract parameters from the URL
    const paymentIntent = searchParams.get('payment_intent');
    const clientSecret = searchParams.get('payment_intent_client_secret');
    const redirectStatus = searchParams.get('redirect_status');

    useEffect(() => {
        // Make an API call to fetch payment details if paymentIntent and clientSecret are available
        const fetchPaymentDetails = async () => {
            if (!paymentIntent || !clientSecret) {
                setErrorMessage("Invalid payment details.");
                return;
            }

            try {
                const response = await axiosInstance.post('payment/validatepayment', {
                    paymentIntent,
                    clientSecret,
                });

                setPaymentDetails(response.data); // Set fetched payment details
            } catch (error) {
                setErrorMessage("Error fetching payment details. Please try again.");
            }
        };

        fetchPaymentDetails();
    }, [paymentIntent, clientSecret]);

    return (
        <>
            <Navbar />
            <div className="success-page-container flex justify-center items-center h-screen bg-gray-100">
                <div className="card w-full md:w-1/2 lg:w-1/3 bg-white shadow-lg rounded-lg p-6">
                    <h2 className="text-center text-3xl font-bold text-green-600 mb-4">
                        {redirectStatus === "succeeded" ? "Payment Successful!" : "Payment Failed"}
                    </h2>
                    {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
                    {paymentDetails ? (
                        <div className="payment-info text-center">
                            <p className="text-lg">Thank you for your payment.</p>
                            <p className="text-gray-700">Transaction ID: {paymentDetails.id}</p>
                            <p className="text-gray-700">Amount: ${(paymentDetails.amount / 100).toFixed(2)}</p>
                            <p className="text-gray-700">Status: {paymentDetails.status}</p>
                        </div>
                    ) : (
                        <p className="text-center text-gray-600 mt-4">Loading payment details...</p>
                    )}
                    <div className="text-center mt-6">
                        <a href="/" className="text-blue-500 underline">Back to Home</a>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
