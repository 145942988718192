import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { CartContextProvider } from "./context/CartContext";
import { TransContextProvider } from "./context/TransContext";
import { CurrencyProvider } from "./context/Currency";
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <CartContextProvider>
        <TransContextProvider>
          <CurrencyProvider>
            <RecoilRoot>
              <App />
            </RecoilRoot>
          </CurrencyProvider>
        </TransContextProvider>
      </CartContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
