import React, { useState, useContext } from 'react';
import { TransContext } from '../../context/TransContext';
import { AuthContext } from '../../context/AuthContext';
import { CartContext } from '../../context/CartContext';
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axiosInstance from '../../axiosInstance';
import { useNavigate } from 'react-router-dom';


export default function PayWindow() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { priceDetails, shippingCharge } = useContext(TransContext);
  const { user } = useContext(AuthContext);
  const { cart, dispatch: cartDispatch } = useContext(CartContext);

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const userAddress = `${user.address.addressLine1}, ${user.address.addressLine2}, ${user.address.city}-${user.address.zipCode}, ${user.address.state}`;

  const handleEmptyCart = async () => {
    try {
      const res = await axiosInstance.post(`/user/cart/${user._id}`, []);
      console.log(res, "res");
      cartDispatch({ type: "EMPTY_CART" });
    } catch (error) {
      console.log("Error emptying cart:", error);
    }
  };

  const notifyUser = async (paymentInfo) => {
    try {
      const userEmailContent = {
        to: user.email,
        subject: 'Order Received Successfully',
        body: `<p>Order ID: ${paymentInfo.id}</p><p>Your order will be delivered in 7 days.</p>`
      };
      await axiosInstance.post(`/mail/send`, userEmailContent);

      const adminEmailContent = {
        to: 'neebu.patron@gmail.com',
        subject: 'New Order Received',
        body: `<h4>Order ID: ${paymentInfo.id}</h4><p>User: ${user.first_name} ${user.last_name}</p><p>Address: ${userAddress}</p>`
      };
      await axiosInstance.post(`/mail/send`, adminEmailContent);
    } catch (error) {
      console.log("Error sending email:", error);
    }
  };

  const handleTransaction = async (paymentInfo) => {
    const transactionDetails = {
      user_id: user._id,
      order_id: paymentInfo.id,
      payment_id: paymentInfo.id,
      transaction_status: "success",
      product_list: cart,
      product_pay: priceDetails.totalPrice,
      shipping_charge: shippingCharge.charge,
      delivery_address: userAddress,
      ordered_date: new Date().toISOString(),
      order_status: "Pending",
    };

    try {
      await axiosInstance.post(`/transaction/`, transactionDetails);
      await notifyUser(paymentInfo);
      await handleEmptyCart(); // Empty the cart after transaction completion
      navigate(`/success?payment_intent=${paymentInfo.id}&payment_intent_client_secret=${paymentInfo.client_secret}&redirect_status=succeeded`);
    } catch (error) {
      console.log("Error recording transaction:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsLoading(true);
    setMessage("Payment in Progress");

    try {
      const result = await stripe.confirmPayment({
        elements,
        redirect: 'if_required', // Do not redirect on success
      });

      if (result.error) {
        setMessage(result.error.message || "Payment failed.");
      } else if (result.paymentIntent.status === 'succeeded') {
        await handleTransaction(result.paymentIntent); // Process transaction after successful payment
        setMessage("Payment successful!");
      }
    } catch (error) {
      setMessage("An unexpected error occurred.");
      console.log("Error during payment:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="infoWindow paywide">
      <div className="payWindowContainer">
        <form onSubmit={handleSubmit}>
          <div className="card w-100 bg-base-100 bg-gray-200 shadow-2xl rounded-lg">
            <div className="card-body p-6">
              <h1 className="card-title font-bold text-2xl mb-4 justify-center">
                Complete your payment here!
              </h1>
              <PaymentElement />
              <div className="card-actions justify-center">
                <button
                  className="btn btn-primary rounded-xl text-black px-4 py-2 mt-6"
                  disabled={isLoading || !stripe || !elements}
                >
                  {isLoading ? "Loading..." : "Pay now"}
                </button>
              </div>
              {message && <div>{message}</div>}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
