import React, { useEffect, useState } from 'react'

//importing css 
import './store.css'

//importing require components
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'

//importing uselocation for fetching product keyword 
import { useLocation, useNavigate } from 'react-router-dom'

//importing components
import useFetch from '../../hooks/useFetch'
import Slider1 from '../../assets/slider-1.jpeg';
import { useCurrency } from '../../context/Currency'
import Stole from '../../assets/homeStole.jpeg';

export default function Store() {
  const { symbol } = useCurrency()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const categoryId = searchParams.get('categoryId');
  const categoryName = searchParams.get('category');

  const [selectedCategory, setSelectedCategory] = useState(categoryId ?? "View all");
  const [category, setCategory] = useState("All");
  const { data, loading } = useFetch(`/product`)

  const { data: categoryData } = useFetch('/category')
  const nav = useNavigate()

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const filteredProducts = selectedCategory === "View all"
    ? data
    : data.filter((product) => product.categoryId === selectedCategory);
  const categoryKey = categoryData?.find((x) => x._id === categoryId)?.keyword

  useEffect(() => {
    if (categoryKey) {
      setCategory(categoryKey);
    }
  }, [categoryKey]);

  const filterCategory = category === "All" ? categoryData : categoryData?.filter((x) => (x.keyword === category))
  const handleTabClick = (tab) => {
    setCategory(tab);
  };
  console.log(categoryName, "category")
  return (
    <>
      <Navbar />
      <div className="headerImageContainer mt-16">
        {/* <img className="headerImg" src={categoryName === "Stoles" ? Stole : "https://img.freepik.com/free-photo/close-up-silky-…fabric-material_23-2147921828.jpg?semt=ais_hybrid" ?? Slider1} alt="slide" /> */}
        <img className="headerImg" src={categoryName
          ? (categoryName === "Stoles"
            ? Stole
            : "https://img.freepik.com/free-photo/close-up-silky-…fabric-material_23-2147921828.jpg?semt=ais_hybrid")
          : Slider1} alt="slide" />
        <div className="tint">
          <div className="text-white font-thin text-3xl md:text-6xl">{
            categoryName
              ? (categoryName === "Stoles"
                ? "Scarves, Stoles, and Shawls"
                : "Fabrics")
              : "Featured collections"
          }
          </div>
        </div>
      </div>
      <div className="mt-5 px-4 max-w-7xl mx-auto">
        <div className="flex flex-col">
          <div className="text-gray-500 text-sm cursor-pointer">
            <span className="cursor-pointer" onClick={() => nav("/")}>Home</span> /
            {categoryName && <span className="ml-1" onClick={() => nav(-1)}>Categories /</span>}
            <span className="ml-1">{filterCategory?.find((x) => x._id === selectedCategory)?.catname}</span>
          </div>
          {
            !categoryName && <div className="flex gap-4">
              {["All", "Fabrics", "Stoles"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => handleTabClick(tab)}
                  className={`rounded-md ${category === tab ? "underline" : "text-gray-700"}`}
                >
                  {tab === "Stoles" ? "Scarves, Stoles, and Shawls" : tab}
                </button>
              ))}
            </div>
          }
        </div>
        <div className="max-w-7xl mx-auto my-10">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="md:w-1/4">
              <div className="relative w-full mb-6">
                <select
                  className="block w-full appearance-none bg-white border border-gray-300 rounded-md px-3 py-2 pr-8 text-gray-700 focus:outline-none focus:ring-1 focus:ring-gray-400"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  {category === "All" && <option>View all</option>}
                  {filterCategory?.map((category, index) => (
                    <option key={index} value={category._id}>
                      {category.catname}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg className="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                  </svg>
                </div>
              </div>
            </div>

            {/* Product Grid */}
            <div className="md:w-3/4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {loading ? (
                <div className="loading">Loading...</div>
              ) : (
                filteredProducts.length === 0 ? (
                  <span>There is no item for your search🙂‍↕️</span>
                ) : (
                  filteredProducts.map(product => (
                    <div key={product._id} className="border p-4 rounded-md shadow-md cursor-pointer relative" onClick={() => nav("/productPage", { state: product })}>
                      {product.stocks === 0 && (
                        <div className="absolute top-0 right-0 bg-gray-800 text-white text-xs font-bold px-2 py-1 rounded-bl-md">
                          Sold Out
                        </div>
                      )}
                      <img src={product.images[0]} alt={product.title} className="w-full h-64 object-cover rounded-md" loading="lazy" />
                      <div className="mt-4">
                        <h2 className="text-lg font-medium text-gray-800">{product.title}</h2>
                        <p className="text-gray-500 mt-2">{symbol}{product.price}</p>
                      </div>
                    </div>
                  ))
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
