import React from 'react'
//importing css
import './searchItem.css'
//importign motion
import { motion } from 'framer-motion'
import { useCurrency } from '../../context/Currency'

export default function SearchResult({ productImg, productTitle, sellingPrice, orgPrice }) {

  const { symbol } = useCurrency()
  const transition = {
    duration: 0.5,
    delay: 0.2,
    ease: [0, 0.71, 0.2, 1.01]
  }

  return (
    <motion.div className='searchItem'
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={transition}
    >
      <img className='searchItemImg' alt='' src={productImg}></img>
      <div className='searchProductDesc'>
        <span className='searchProductTitle'>{productTitle}</span>
        <div className='searchProductPrice'>
          <span className='ProductPrice'>{symbol}{sellingPrice}</span>
          {orgPrice !== undefined && (<span className='orgPrice'>{symbol}{orgPrice}</span>)}
        </div>
      </div>
    </motion.div>
  )
}
