import React, { useContext, useEffect, useState } from 'react';
import stole1 from './../../assets/Stoles-1-2.jpg';
import stole2 from './../../assets/Stoles-1-3.jpg';
import stole3 from './../../assets/Stoles-1-4.jpg';
import stole4 from './../../assets/Stoles-2-1.jpg';
import stole5 from './../../assets/Stoles-2-2.jpg';
import stole6 from './../../assets/Stoles-2-3.jpg';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import { useLocation } from 'react-router-dom';
import { useCurrency } from '../../context/Currency';
import { CartContext } from '../../context/CartContext';
import { TransContext } from '../../context/TransContext';
import { AuthContext } from '../../context/AuthContext';
import axiosInstance from '../../axiosInstance';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

const ProductPage = () => {
    const { symbol } = useCurrency()
    const location = useLocation()
    const { state } = location;
    const { dispatch } = useContext(CartContext)
    const TransObj = useContext(TransContext)
    const { user } = useContext(AuthContext)
    const transDispatch = TransObj.dispatch
    const { totalPrice, totalSaving } = TransObj.priceDetails
    const productData = {
        name: 'Noah Kashmiri stole',
        price: 'Rs. 9,500.00 INR',
        description: [
            'A window-pane check pattern in light gauze weave cashmere. A stole with sharp style to be worn all year round.',
            'The fiber in this stole is the finest cashmere from the Changra goat, which lives in Ladakh in India. The fiber is handwoven in Kashmir into this beautiful stole.',
            'Composition: 100% Pashmina',
            'Care and Maintenance: Dry clean only. Warm iron.',
            'The ends are finished with a natural fringe. Each piece bears the Kashmir Loom Signature in Farsi language, embroidered in the corner.',
        ],
        size: '70 x 200 cm (27 x 80 inches)',
        images: [stole1, stole2, stole3, stole4, stole5, stole6],
        colors: [
            { name: 'Noah Laurie Mid blue', image: stole1 },
            { name: 'Red', image: stole2 },
            { name: 'Green', image: stole3 },
            { name: 'Pink', image: stole4 },
        ],
    };

    // State for selected image
    const [selectedImage, setSelectedImage] = useState(state.images[0]);

    // State for selected color
    const [selectedColor, setSelectedColor] = useState(productData.colors[0].name);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const changeCartItemRequest = async (cartItems, sellingPrice, discountedMoney) => {
        const userId = user._id
        try {
            await axiosInstance.post(`user/cart/${userId}`, cartItems)
            dispatch({ type: "CART_CHANGE", payload: cartItems })
            transDispatch({ type: "TRANS_PRICE", payload: { totalPrice: totalPrice + (sellingPrice * 1), totalSaving: totalSaving + (discountedMoney) } })
        } catch (err) {
            console.log("error occured")
            dispatch({ type: "CART_FAILURE", payload: err.response.data })
        }
    }

    const handleCart = (productId, sellingPrice, discountedMoney, productSize) => {
        const cartObject = {
            productId,
            qnt: 1,
            size: productSize
        }
        let cartItems = JSON.parse(localStorage.getItem("cart")) || null

        if (cartItems === null) {
            cartItems = []
            cartItems.push(cartObject)
        } else {
            let isProductAvailable = false
            cartItems.forEach(element => {
                if (element.productId === productId) {
                    element.qnt = element.qnt + 1
                    isProductAvailable = true
                }
            });

            if (isProductAvailable === false) {
                cartItems.push(cartObject)
            }
        }

        if (user !== null) {
            changeCartItemRequest(cartItems, sellingPrice, discountedMoney)
        } else {
            dispatch({ type: "CART_CHANGE", payload: cartItems })
            transDispatch({ type: "TRANS_PRICE", payload: { totalPrice: totalPrice + (sellingPrice * 1), totalSaving: totalSaving + (discountedMoney * 1) } })
        }

    }
    let isDiscountAvailable = false
    let orgPrice = state.price;
    let sellingPrice;
    let savingMoney = 0;
    if (state.discount !== 0) {
        isDiscountAvailable = true
        savingMoney = Math.floor((state.discount * orgPrice) / 100)
        sellingPrice = orgPrice - savingMoney
    } else {
        sellingPrice = orgPrice
    }
    console.log(state, "state")
    return (
        <>
            <Navbar />
            <div className="container mx-auto flex flex-col lg:flex-row bg-white min-h-screen px-4 md:px-20 py-8 text-gray-800 mt-16">
                {/* First Column - Thumbnails and Main Image */}
                <div className="flex flex-col lg:flex-row w-full lg:w-1/2">
                    {/* Left Side - Thumbnails */}
                    <div className="w-full lg:w-1/5 h-[100px] overflow-y-auto lg:h-[600px]"> {/* Adjusted height for thumbnails */}
                        <div className="flex lg:flex-col lg:space-y-4 space-x-2 lg:space-x-0 h-full">
                            {state.images.map((img, idx) => (
                                <img
                                    key={idx}
                                    src={img}
                                    alt={`Thumbnail ${idx + 1}`}
                                    className={`cursor-pointer w-20 h-[80px] object-cover rounded-md transition-all duration-300 ${selectedImage === img ? 'border-2 border-gray-900' : 'border border-gray-300'}`}
                                    onClick={() => setSelectedImage(img)}
                                    loading='lazy'
                                />
                            ))}
                        </div>
                    </div>

                    {/* Right Side - Main Image */}
                    <div className="flex-1 lg:w-4/5 lg:pl-4 mb-2 lg:mb-0">
                        <InnerImageZoom
                            src={selectedImage}
                            zoomSrc={selectedImage} // You can use a higher resolution image here if available
                            zoomType="hover" // This shows zoom effect on hover
                            zoomPreload={true} // Preloads zoom image for a smoother experience
                            alt="Main Product"
                            className="h-[300px] w-full lg:h-[600px] object-cover rounded-md shadow-lg"
                        />
                    </div>
                </div>

                {/* Second Column - Product Info */}
                <div className="flex-1 lg:w-1/2 lg:pl-6 flex flex-col">
                    <div className="mb-4">
                        <h2 className="text-xl lg:text-4xl font-bold">{state.title}</h2>
                        <p className="text-xl text-gray-900">{symbol} {state.price}</p>
                    </div>
                    <hr />
                    <br />

                    {/* Color Selection */}
                    {/* <div className="mb-4">
                    <h3 className="text-lg font-semibold mb-2">COLOR — {selectedColor}</h3>
                    <div className="flex space-x-2 overflow-x-auto">
                        {productData.colors.map((color, idx) => (
                            <div
                                key={idx}
                                className={`cursor-pointer p-1 border rounded-md ${selectedColor === color.name ? 'border-gray-900' : 'border-gray-300'}`}
                                onClick={() => {
                                    setSelectedImage(color.image);
                                    setSelectedColor(color.name);
                                }}
                            >
                                <img
                                    src={color.image}
                                    alt={color.name}
                                    className="w-8 h-8 object-cover rounded-md"
                                />
                            </div>
                        ))}
                    </div>
                </div> */}

                    {/* Size Information */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold">SIZE</h3>
                        <p>{state.size}</p>
                    </div>
                    {state.yard_meter && <div className="mb-4">
                        <h3 className="text-lg font-semibold">Yards/meter</h3>
                        <p>{state.yard_meter}</p>
                    </div>}
                    {/* Add to Cart Button */}
                    <button
                        className={`w-full py-3 rounded-lg font-semibold transition duration-200 
    ${state?.stocks !== 0 ? "bg-gray-900 text-white hover:bg-gray-700" : "bg-gray-400 text-gray-300 cursor-not-allowed"}`}
                        onClick={() => state.stocks !== 0 && handleCart(state._id, sellingPrice, savingMoney, state.size)}
                        disabled={state.stocks === 0}
                    >
                        {state?.stocks !== 0 ? "Add to Cart" : "Out of Stock"}
                    </button>
                    {/* Product Description */}
                    <div className="mt-8 flex-1 overflow-y-auto">
                        {state.desc.map((desc, idx) => {
                            // Split on the first colon while preserving the newline characters
                            const [field, ...contentParts] = desc.split(/:\s*/);
                            const content = contentParts.join(':').trim(); // Join any remaining parts and trim whitespace

                            return (
                                <p key={idx} className="mt-4 text-gray-700 leading-relaxed">
                                    {content ? (
                                        <>
                                            <span className="font-bold">{field}:</span>
                                            <br />
                                            {content.split('\n').map((line, lineIdx) => (
                                                <React.Fragment key={lineIdx}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </>
                                    ) : (
                                        desc // Render the whole string if no colon is found
                                    )}
                                </p>
                            );
                        })}
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
};

export default ProductPage;
