import React from 'react'


import useFetch from '../../hooks/useFetch'

import { Link, useNavigate } from 'react-router-dom'
import { useCurrency } from '../../context/Currency'

export default function OrderItem({ productId }) {
  const { symbol } = useCurrency()
  const nav = useNavigate()
  const { data, loading } = useFetch(`/product/find/${productId}`)
  console.log(data, "data")
  let isDiscountAvailable = false
  let orgPrice = data?.price;
  let sellingPrice;
  let savingMoney = 0;
  if (data?.discount !== 0) {
    isDiscountAvailable = true
    savingMoney = Math.floor((data?.discount * orgPrice) / 100)
    sellingPrice = orgPrice - savingMoney
  } else {
    sellingPrice = orgPrice
  }

  let productTitle = data?.title
  if (data?.length !== 0) {

    if (productTitle?.length > 32) {
      productTitle = productTitle.slice(0, 32)
      productTitle += "..."
    }
  }



  return (
    (loading === true) ? (<span>Loading please wait...</span>) : (
      (data?.length !== 0) && (
        <div className='cursor-pointer' style={{ textDecoration: 'none' }} onClick={() => nav("/productPage", { state: data })}>
          <div className='orderitem'>
            <div className='orderimage'>
              <img src={data?.images[0]} alt=''></img>
            </div>
            <div className='ordercontent'>
              <span className='ordertitle'>{productTitle}</span>
              <span className='orderprice'>{symbol}{sellingPrice} {isDiscountAvailable && <span className='orgPrice'>{symbol}{orgPrice}</span>} </span>
              <span className='orderqnt'>size: {data?.size}</span>
            </div>
          </div>
        </div>
      )
    )

  )
}
