import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

//importing css
import './footer.css'


//importing icons
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import Logo from './../../assets/weavesFabricsLogo.png'
import FooterRow from './../../assets/footerColumn.png';
import FooterColumn from './../../assets/footerRow.png';
export default function Footer() {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className='footer'
            style={{
                backgroundImage: `url(${isSmallScreen ? FooterRow : FooterColumn})`,
                backgroundSize: isSmallScreen ? "contain" : "cover"
            }}
        >
            <div className='footerSec'>
                <div className='footerItem'>
                    <span className='footerLogo'>
                        <img src={Logo} alt="logo" width={150} />
                    </span>
                    <div className='socialLinks'>
                        <span className='socialTitle'>Follow Us:</span>
                        <div className='links'>
                            <a href="/" className='linkItem'><InstagramIcon></InstagramIcon> Instagram</a>
                            <a href="/" className='linkItem'><FacebookIcon></FacebookIcon> Facebook</a>
                            {/* <a href="/" className='linkItem'><LinkedInIcon></LinkedInIcon> LinkedIn</a> */}
                        </div>
                    </div>
                </div>
                <div className='footerItem'>
                    <span className='quickTitle' >Quick Links</span>
                    <div className='quickLinks'>
                        <Link style={{ textDecoration: 'none' }} to='/'><a className='quickItem' href="##">Home</a></Link>
                        <Link style={{ textDecoration: 'none' }} to='/store'><a className='quickItem' href="##">Products</a></Link>
                        <Link style={{ textDecoration: 'none' }} to='/about'><a className='quickItem' href="##">About us</a></Link>
                        {/* <Link style={{ textDecoration: 'none' }} to='/'><a className='quickItem' href="##">Our Story</a></Link> */}
                        {/* <Link style={{ textDecoration: 'none' }} to='/'><a className='quickItem' href="##">Contact Us</a></Link> */}
                        {/* <Link style={{ textDecoration: 'none' }} to='/' className='quickItem'>Career</Link> */}
                    </div>
                </div>



                <div className='footerItem'>
                    <span className='helpTitle'>Help</span>
                    <div className='helpLinks'>
                        <Link style={{ textDecoration: 'none' }} to='/tos' className='helpItem'>Terms & Condition</Link>
                        <Link style={{ textDecoration: 'none' }} to='/tos' className='helpItem'>Refund & Return</Link>
                        <Link style={{ textDecoration: 'none' }} to='/tos' className='helpItem'>Shipping Policy</Link>
                    </div>
                </div>

                <div className='footerItem'>
                    <span className='footerContactTitle'> Contact</span>
                    <div className='contacts'>
                        {/* <div className='contactItem'>
                            <LocationOnIcon></LocationOnIcon>
                            <span className='contacttextaddress' style={{ lineHeight: 1.5 }}>Ahmedabad, Gujarat-382430</span>
                        </div>
                        <div className='contactItem'>
                            <CallIcon></CallIcon>
                            <div className='contactTexts'>
                                <a href='/' className='contacttext'>1234567890</a>
                            </div>
                        </div> */}
                        <div className='contactItem'>
                            <EmailIcon></EmailIcon>
                            <div className='contactTexts'>
                                <a href='/' className='contacttext'>weavesfabrics12@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerItem"></div>
            </div>
            <div className='footerCopyright'>
                <span className='copyText'>&#169; 2024 Developed by StellarMind.ai | all rights reserved</span>
            </div>
        </div>
    )
}
