import { createContext, useContext, useState } from "react";

// Currency symbols by region
const currencySymbols = {
  aus: "A$",
  europe: "€",
  uk: "£",
  nz: "NZ$",
  usa: "$",
  germany: "€",
};

// Create context
const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  // Default to USA currency
  const [currency, setCurrency] = useState("usa");

  // Function to change currency
  const changeCurrency = (region) => {
    setCurrency(region);
  };

  return (
    <CurrencyContext.Provider
      value={{ symbol: currencySymbols[currency], changeCurrency }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

// Custom hook to use currency context
export const useCurrency = () => useContext(CurrencyContext);
