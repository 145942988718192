import React, { useState, useEffect } from 'react'

//importing components
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'

//importing css
import './register.css'

import { Link } from 'react-router-dom'
import axios from 'axios'

import { useNavigate } from 'react-router-dom'

export default function Register() {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const baseURL = process.env.REACT_APP_API_BASE_URL
  const navigate = useNavigate()
  const [credantial, setCredantial] = useState({
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    password: undefined
  })

  const [error, setError] = useState(null)

  const handleChange = (e) => {
    setCredantial((prev) => ({ ...prev, [e.target.id]: e.target.value }))
  }


  const saveregister = async () => {
    try {
      setLoading(true)
      await axios.post(`${baseURL}/auth/register`, credantial)
      setLoading(false)
      navigate('/login', { state: "Successfully registered, Now please Login..." })
    } catch (err) {
      setLoading(false)
      setError(err.response.data.message)
    }
  }

  const handleRegister = async (e) => {
    e.preventDefault()
    //regax for validate email address
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (Object.keys(credantial).length !== 4) {
      setError("Please enter all marked fields..!")
    } else if (credantial.password.length < 6) {
      setError("Password length must be equal to 6 or greater..!")
    } else if (!emailRegex.test(credantial.email)) {
      setError("Please enter valid email address..!")
    } else {
      setError(null)
      saveregister()
    }


  }
  return (
    <div>
      <Navbar></Navbar>
      <div className="flex justify-center items-center mt-[3.90rem] p-8 md:p-16 bg-gray-50">
        <div className="w-full max-w-md p-8 bg-white border border-gray-200 shadow-md space-y-6">
          <h2 className="text-3xl font-semibold text-center text-gray-800">Create Account</h2>
          <form onSubmit={handleRegister} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">First Name</label>
              <input
                type="text"
                id="first_name"
                onChange={handleChange}
                placeholder="Enter your first name"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded outline-none focus:ring focus:ring-gray-300"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Last Name</label>
              <input
                type="text"
                id="last_name"
                onChange={handleChange}
                placeholder="Enter your last name"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded outline-none focus:ring focus:ring-gray-300"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Email</label>
              <input
                type="email"
                id="email"
                onChange={handleChange}
                placeholder="Enter your email address"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded outline-none focus:ring focus:ring-gray-300"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Password</label>
              <input
                type="password"
                id="password"
                onChange={handleChange}
                placeholder="Enter your password"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded outline-none focus:ring focus:ring-gray-300"
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full px-4 py-2 mt-4 text-white bg-gray-600 rounded hover:bg-gray-700 focus:outline-none"
            >
              Sign Up
            </button>
          </form>
          <div className="flex justify-center items-center text-gray-500">
            <span>Already have an account?</span>
            <Link to="/login" className="ml-2 text-gray-600 hover:text-gray-800 underline">
              Login
            </Link>
          </div>
          {error && <span className="block text-red-500 text-center">{error}</span>}
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}
