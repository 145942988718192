import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axiosInstance from '../../axiosInstance';

export default function MainContactWindow({ mobileNo, setMobileNo, setContactWindow, setAddressWindow }) {
   const [error, setError] = useState(null);
   const [countryCode, setCountryCode] = useState('+1'); // Default to US code
   const { user, dispatch } = useContext(AuthContext);

   const countryCodes = {
      'US': '+1',
      'EU': '+44', // Europe - assuming UK code
      'UK': '+44',
      'AU': '+61',
      'NZ': '+64',
      'DE': '+49', // Germany
   };

   const validationPatterns = {
      '+1': /^[2-9]\d{9}$/,         // USA
      '+44': /^7\d{9}$/,            // UK/EU, mobile starts with 7
      '+61': /^[4]\d{8}$/,          // Australia, mobile starts with 4
      '+64': /^[2-9]\d{7,8}$/,      // New Zealand
      '+49': /^[1-9]\d{10}$/,       // Germany
   };

   const maxLengths = {
      '+1': 10,  // USA
      '+44': 10, // UK/EU
      '+61': 9,  // Australia
      '+64': 8,  // New Zealand
      '+49': 11, // Germany
   };

   const handleMobileChange = (e) => {
      const value = e.target.value;
      setMobileNo(value);

      const pattern = validationPatterns[countryCode];
      if (pattern && !pattern.test(value)) {
         setError('Invalid mobile number for the selected country.');
      } else {
         setError(null);  // Clear error when valid
      }
   };

   const handleSave = async () => {
      const pattern = validationPatterns[countryCode];
      if (pattern && !pattern.test(mobileNo)) {
         setError('Please enter a valid mobile number to save.');
         return;
      }

      try {
         const res = await axiosInstance.put(
            `user/${user._id}`,
            { mobileno: countryCode + mobileNo, verified: true },
            { withCredentials: true }
         );
         dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
         setContactWindow(false);
         setAddressWindow(true);
      } catch (err) {
         setError("There is something wrong...!");
         dispatch({ type: "LOGIN_FAILURE", payload: "error-savemobileno" });
      }
   };

   const handleSkip = () => {
      // Skip the contact step and move to the next window
      setContactWindow(false);
      setAddressWindow(true);
   };

   return (
      <div className='infoWindow'>
         <div className='infoMainBody'>
            <h1 className='mainText'>Get in Touch for Order Delivery</h1>
            <div className='inputBox'>
               <select
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  className='w-[100px]'
               >
                  {Object.keys(countryCodes).map((country) => (
                     <option key={country} value={countryCodes[country]}>
                        {country} ({countryCodes[country]})
                     </option>
                  ))}
               </select>
               <input
                  className='inputMobileNo'
                  type='number'
                  value={mobileNo}
                  onChange={handleMobileChange}
                  placeholder='Enter Number'
                  maxLength={maxLengths[countryCode] || 10} // Default to 10 if undefined
               />
            </div>
         </div>
         <div className="buttonGroup flex flex-row gap-5">
            <button onClick={handleSave} disabled={!mobileNo || error} className='cursor-pointer hover:text-gray-500'>
               SAVE
            </button>
            <button onClick={handleSkip} className='cursor-pointer hover:text-gray-500'>
               SKIP
            </button>
         </div>
         {error && <span className='errMsg'>{error}</span>}
      </div>
   );
}
