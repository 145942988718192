import React from 'react'
//importing components
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
//importing css
import './tos.css'
import { useEffect } from 'react'

export default function TOS() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar></Navbar>
      <div className='tos'>
        <div className='toshead'>
          <h2 className='text-center font-bold'>Orders, Shipping, Payments & Terms</h2>
        </div>
        <div className='toscontent'>
          <h2 className='font-bold'>Introduction</h2>
          <p>Weaves Fabrics, we are proud to bring the beauty of Indian craftsmanship to customers all
            around the world. Our International Orders Policy is designed to provide you with all the
            information you need to ensure a smooth and seamless shopping experience, no matter
            where you are located. We are committed to transparency and fairness in all aspects of our
            business, from pricing to shipping.</p>
          <p>This page covers everything you need to know about placing an international order with
            Weaves Fabrics, including shipping options, rates, customs, payment methods, and more.
            Please read this policy carefully to understand your rights and our procedures.</p>

          <h2 className='font-bold'>1. International Delivery</h2>
          <h3 className='font-bold'>Global Shipping Partners</h3>
          <p>We deliver internationally to most countries around the globe through our trusted logistics
            partners, including:</p>
          <ul>
            <li>USPS (USA Speedpost)</li>
            <li>FedEx</li>
            <li>UPS</li>
            <li>DHL Express</li>
          </ul>
          <p>These partners are selected based on their reliability and efficiency, ensuring that your
            orders are delivered safely and on time.</p>

          <h2 className='font-bold'>Shipping Options</h2>
          <p>During checkout, you can choose your preferred shipping method from the options
            provided. The available methods will be displayed based on your location, and the shipping
            rate calculator will automatically show the shipping fee applied to your selection. We strive
            to offer the best shipping options available, balancing cost and delivery time.</p>

          <h2 className='font-bold'>International Delivery Timeline</h2>
          <ul>
            <li>
              <h2 className='font-bold'>Order Processing:</h2>
              <p>Once your payment is confirmed, your order will be processed and
                dispatched from our warehouse within 2-3 days. You will receive a dispatch confirmation
                email with tracking details.</p>
            </li>
            <li>
              <h2 className='font-bold'>Estimated Delivery Time:</h2>
              <p>Depending on your location and the shipping method selected,
                delivery typically occurs within 7-14 business days after dispatch. Please note that delivery
                times may vary due to customs clearance procedures or other factors beyond our control.</p>
            </li>
          </ul>

          <h2 className='font-bold'>2. Shipping Rates</h2>
          <h3 className='font-bold'>Transparent and Fair Pricing</h3>
          <p>Weaves Fabrics, we believe in transparency. Our shipping rates are based on the actual
            charges from courier companies like USPS, DHL, FedEx, and UPS. We do not add any
            markup to these charges, ensuring that you only pay what is necessary for the shipping of
            your order.</p>
          <h3 className='font-bold'>Weight-Based Shipping</h3>
          <ul>
            <li>
              <h3 className='font-bold'>Simple and Clear:</h3>
              <p>Shipping rates are calculated based on the weight of your order. The
                shipping rate calculator at checkout will automatically determine the cost based on the
                weight and destination of your package.</p>
            </li>
            <li>
              <h3 className='font-bold'>No Hidden Fees:</h3>
              <p>Unlike some other platforms that might include shipping costs in the
                product price or charge inflated rates for “free” shipping, we keep our product prices
                separate and charge shipping based on actual courier costs. This ensures fairness and
                transparency in all transactions.</p>
            </li>
          </ul>

          <h2 className='font-bold'>Why Shipping Rates May Seem High</h2>
          <ul>
            <li>
              <h3 className='font-bold'>Quality Over Cost:</h3>
              <p>International Priority Shipping is a premium service that ensures your
                products arrive quickly and safely. While we understand that shipping rates may seem
                higher compared to other e-commerce websites, this is because we prioritize quality and
                reliability over low-cost shipping options that could compromise the safety and speed of
                delivery.</p>
            </li>
            <li>
              <h3 className='font-bold'>No Free Shipping:</h3>
              <p>We do not offer “free” international shipping because we believe in fair
                pricing. Many websites inflate product prices to cover the cost of “free” shipping. Weaves
                Fabrics, we charge shipping based on the actual value of the order, which we believe is a
                fair and ethical approach.</p>
            </li>
          </ul>

          <h2 className='font-bold'>Assistance with Shipping Queries</h2>
          <p>If you have any questions or concerns about our shipping rates or options, please feel free
            to reach out to our customer service team at weavesfabrics12@gmaili.com. We are here to
            assist you and ensure that you have a positive shopping experience.</p>

          <h2 className='font-bold'>3. Pricing Policy</h2>
          <h3 className='font-bold'>Consistent Pricing for All Customers</h3>
          <p>Weaves Fabrics, we believe in fairness and transparency. We do not adjust our prices
            based on your location—whether you are shopping from the United States, the United
            Kingdom, Germany, India or any other country, the prices you see on our website are the
            same for everyone.</p>
          <ul>
            <li><h3 className='font-bold'>Single Price Policy:</h3><p>Our products are priced equally for all customers, regardless of their
              geographical location. This approach reflects our commitment to ethical business practices
              and ensures that you receive the same value for your purchase, no matter where you are in
              the world.</p></li>
            <li><h3 className='font-bold'>No Location-Based Markups:</h3><p>We do not increase prices for international customers to
              cover shipping or other costs. Instead, we maintain a transparent pricing structure where
              the product price remains constant, and any shipping costs are calculated separately based
              on actual courier rates.</p></li>
          </ul>

          <h2 className='font-bold'>4. Payment Options</h2>
          <h3 className='font-bold'>Secure and Flexible Payment Methods</h3>
          <p>
            We offer a variety of secure payment options to make your shopping experience as
            convenient as possible.</p>
          <ul>
            <li><h3 className='font-bold'>International Credit/Debit Cards:</h3><p>We accept all major international credit and debit
              cards. Payments are processed securely through our trusted payment gateway, Razorpay,
              which uses SSL encryption technology to protect your card information.</p></li>
            <li><h3 className='font-bold'>PayPal:</h3><p>For added security and convenience, you can choose to pay via PayPal at
              checkout. This option is particularly popular with our international customers, providing an
              additional layer of protection for online transactions.</p></li>
            <li><h3 className='font-bold'>Direct Bank Transfer:</h3><p>If you prefer, you can also make a payment via direct bank/wire
              transfer to our bank account. Details for this option will be provided at checkout or upon
              request.</p></li>
            {/* <li><h3 className='font-bold'>Shipping to India with International Cards:</h3><p>If you are using an international card to ship
              an order to an address within India, simply enter your billing and shipping details
              accordingly at checkout, and our system will process the payment seamlessly.</p></li> */}
          </ul>
          <h2>Payment Assistance</h2>
          <ul>
            <li><h3 className='font-bold'>Payment Issues:</h3><p>If you encounter any issues during the payment process, don’t worry.
              Simply contact our customer service team at weavesfabrics12@gmail.com with your order
              ID, and we will assist you in resolving the issue promptly.</p></li>
            {/* <li><h3 className='font-bold'>Failed Payments:</h3><p>In case of a failed payment, remain calm and reach out to us. Payment
              failures can occur due to various reasons, and our team will work with you to find a solution
              and ensure your order is processed smoothly.</p></li> */}
          </ul>

          <h2 className='font-bold'>5. Customs and Taxes</h2>
          <h3 className='font-bold'>Understanding Customs Duties and Taxes</h3>
          <p>
            When placing an international order withWeaves Fabrics, it’s important to understand that
            customs duties, taxes, and other import fees may apply depending on your country’s
            regulations. These charges are not included in your order total and must be paid separately
            upon delivery.</p>
          <ul>
            <li><h3 className='font-bold'>Responsibility of the Customer:</h3><p>Any customs duties, taxes, or import fees imposed by
              your country’s customs authority are the responsibility of the recipient. Unfortunately, we
              cannot predict or control these charges, as they vary from country to country.</p></li>
            <li><h3 className='font-bold'>Customs Assistance:</h3><p>If you need assistance with understanding customs fees or if you
              encounter issues with customs clearance, our customer service team is here to help. While
              we cannot cover these charges, we will support you throughout the process to ensure your
              order is delivered successfully.</p></li>
          </ul>
          <h2>Customs Delays</h2>
          <ul>
            <li><h3 className='font-bold'>Handling Delays:</h3><p>While we do our best to ensure timely delivery, customs clearance
              procedures may cause delays that are beyond our control. We closely monitor all
              international shipments and will keep you informed if any delays occur. We appreciate your
              patience and understanding in such cases.</p></li>
            <li><h3 className='font-bold'>Local Taxes:</h3><p>In some countries, additional local taxes or fees may be applied to your
              order upon delivery. These are separate from customs duties and are also the responsibility
              of the recipient.</p></li>
          </ul>

          <h2 className='font-bold'>6. Special Requests</h2>
          <h3 className='font-bold'>Custom Orders and Gift Wrapping</h3>
          <p>
            Weaves Fabrics, we understand that sometimes you may have special requests for your
            order. We are happy to accommodate these requests whenever possible to make your
            shopping experience more personalized.</p>
          <ul>
            <li><h3 className='font-bold'>Holding Orders:</h3><p>If you need us to hold your order for any reason, we can do so after
              payment has been completed. Please contact our customer service team with your request,
              and we will arrange to hold your order until you are ready for it to be shipped.</p></li>
            <li><h3 className='font-bold'>Gift Wrapping:</h3><p>We offer complimentary gift wrapping for international orders upon
              request. If you would like your order to be gift-wrapped, simply drop us a mail at
              weavesfabrics12@gmail.com, and we will take care of it for you.</p></li>
            <li><h3 className='font-bold'>Personal Messages:</h3><p>Want to include a personal message with your gift order? Let us
              know, and we’ll handwrite your message on a sheet of handmade paper, adding a special
              touch to your gift.</p></li>
          </ul>
          <h2>Alternative Shipping Options</h2>
          <ul>
            <li><h3 className='font-bold'>Preferred Shippers:</h3><p>While we primarily use DHL, FedEx, UPS, and USPS due to their
              reliability, we are open to using other shipping companies if you have a preferred shipper in
              mind. Contact us with your request, and we will do our best to accommodate it.</p></li>
            <li><h3 className='font-bold'>Shipping Rate Adjustments:</h3><p>If you believe you can find a more affordable shipping
              option, please let us know. We are committed to providing the best possible service and will
              work with you to explore all available options.</p></li>
          </ul>

          <h2 className='font-bold'>6. Special Requests</h2>
          <h3 className='font-bold'>Custom Orders and Gift Wrapping</h3>
          <p>
            Weaves Fabrics, we understand that sometimes you may have special requests for your
            order. We are happy to accommodate these requests whenever possible to make your
            shopping experience more personalized.</p>
          <ul>
            <li><h3 className='font-bold'>Holding Orders:</h3><p>If you need us to hold your order for any reason, we can do so after
              payment has been completed. Please contact our customer service team with your request,
              and we will arrange to hold your order until you are ready for it to be shipped.</p></li>
            <li><h3 className='font-bold'>Gift Wrapping:</h3><p>We offer complimentary gift wrapping for international orders upon
              request. If you would like your order to be gift-wrapped, simply drop us a mail at
              weavesfabrics12@gmail.com, and we will take care of it for you.</p></li>
            <li><h3 className='font-bold'>Personal Messages:</h3><p>Want to include a personal message with your gift order? Let us
              know, and we’ll handwrite your message on a sheet of handmade paper, adding a special
              touch to your gift.</p></li>
          </ul>
          <h2>Alternative Shipping Options</h2>
          <ul>
            <li><h3 className='font-bold'>Preferred Shippers:</h3><p>While we primarily use DHL, FedEx, UPS, and USPS due to their
              reliability, we are open to using other shipping companies if you have a preferred shipper in
              mind. Contact us with your request, and we will do our best to accommodate it.</p></li>
            <li><h3 className='font-bold'>Shipping Rate Adjustments:</h3><p>If you believe you can find a more affordable shipping
              option, please let us know. We are committed to providing the best possible service and will
              work with you to explore all available options.</p></li>
          </ul>

          <h2 className='font-bold'>7. Returns, Cancellations, and Exchanges</h2>
          <h3 className='font-bold'>Returns and Exchanges</h3>
          <p>
            While we strive to ensure your satisfaction with every purchase, we understand that there
            may be instances where you need to return or exchange an item. Due to the nature of
            international shipping, our return and exchange policy is a bit more restrictive.</p>
          <ul>
            <li><h3 className='font-bold'>Before Shipment:</h3><p>If your order has not yet been shipped from our warehouse, you can
              make as many alterations as needed. Please contact us as soon as possible to request
              changes or cancellations.</p></li>
            <li><h3 className='font-bold'>After Shipment:</h3><p>Once your order has been shipped to an international destination,
              returns, exchanges, and cancellations are generally not possible. We recommend
              double-checking your order details before finalizing your purchase.</p></li>
            <li><h3 className='font-bold'>Return Shipping Costs:</h3><p>If you still wish to return the parcel after receiving it, you will
              need to bear the return shipping costs. Once we receive the returned parcel in our
              warehouse with the original tag and unused items. we will issue a full refund of your order
              value.</p></li>
            <li><h3 className='font-bold'>Faulty or Defective Products:</h3><p>If you receive a faulty or defective product, please reach
              out to us immediately. We will do our best to resolve the issue to your satisfaction, which
              may include a replacement or refund, depending on the situation.</p></li>
          </ul>
          <h2>Cancellations</h2>
          <ul>
            <li><h3 className='font-bold'>Order Cancellations:</h3><p>Orders can be canceled before they are dispatched from our
              warehouse. If you need to cancel your order, please contact us as soon as possible. Once
              the order has been shipped, it cannot be canceled.</p></li>
            <li><h3 className='font-bold'>Refund Process:</h3><p>If you cancel an order before shipment, we will process a full refund to
              your original payment method. The refund will be initiated within [10 - 15 business days],
              though it may take additional time for the refunded amount to reflect in your account,
              depending on your payment provider.</p></li>
          </ul>

          <h2 className="font-bold">8. Tracking Your Order</h2>
          <h3 className="font-bold">Real-Time Tracking</h3>
          <p>
            Once your order has been shipped, you will receive a tracking number via email,
            allowing you to monitor the status of your shipment in real-time.
          </p>
          <ul>
            <li>
              <h3 className="font-bold">Tracking Information:</h3>
              <p>
                You can track your order through the tracking link provided in the dispatch
                confirmation email or by visiting the courier’s website. This link will provide you
                with updates on your order’s journey from our warehouse to your doorstep.
              </p>
            </li>
            <li>
              <h3 className="font-bold">Updates and Notifications:</h3>
              <p>
                We keep a close eye on all international shipments and will notify you if there are
                any delays or issues during transit. If you have any concerns about the tracking
                information, please don’t hesitate to contact us.
              </p>
            </li>
          </ul>
          <h3 className="font-bold">Customs Delays</h3>
          <ul>
            <li>
              <h3 className="font-bold">Monitoring:</h3>
              <p>
                We monitor all international shipments closely, especially during customs
                clearance. While we do our best to prevent delays, customs procedures in your
                country may cause unexpected hold-ups. In such cases, we appreciate your patience
                and will work with you to resolve any issues.
              </p>
            </li>
            <li>
              <h3 className="font-bold">Customer Support:</h3>
              <p>
                If your order is delayed due to customs or other issues, our customer service team
                is here to assist you. We will liaise with the courier and customs authorities to
                ensure your order is delivered as quickly as possible.
              </p>
            </li>
          </ul>

          <h2 className="font-bold">9. Security of Your Information</h2>
          <h3 className="font-bold">Secure Transactions</h3>
          <p>Weaves Fabrics, the security of your personal and payment information is of utmost
            importance to us.</p>
          <ul>
            {/* <li>
              <h3 className="font-bold">SSL Encryption:</h3>
              <p>
                All transactions on our website are protected by Secure Sockets Layer (SSL)
                encryption, ensuring that your credit/debit card details and other personal
                information are securely transmitted over the internet.
              </p>
            </li>
            <li>
              <h3 className="font-bold">PCI Compliance:</h3>
              <p>
                Our payment gateways, including Razorpay and PayPal, are PCI DSS (Payment Card
                Industry Data Security Standard) compliant, which means they meet the highest
                security standards for handling and storing payment information.
              </p>
            </li> */}
            <li>
              <h3 className="font-bold">Data Privacy:</h3>
              <p>
                We do not store your card information or PIN data on our servers. Your payment
                details are handled directly by our secure payment gateways, ensuring that your
                information is kept private and secure at all times.
              </p>
            </li>
          </ul>
          <h3 className="font-bold">Protecting Your Data</h3>
          <ul>
            <li>
              <h3 className="font-bold">No Data Sharing:</h3>
              <p>
                We respect your privacy and do not share your personal information with third
                parties, except as necessary to process your payment or fulfill your order.
              </p>
            </li>
            <li>
              <h3 className="font-bold">Ongoing Security Monitoring:</h3>
              <p>
                We continuously monitor our systems for any potential security threats and take
                immediate action to protect your data in case of any vulnerabilities.
              </p>
            </li>
          </ul>
          <h3 className="font-bold">Addressing Security Concerns</h3>
          <p>
            If you have any concerns about the security of your transactions or personal
            information, please contact us at <a href="mailto:weavesfabrics12@gmail.com" className="text-blue-600 underline">weavesfabrics12@gmail.com</a>.
            We are committed to providing a secure and trustworthy shopping experience and will address any concerns you may have promptly.
          </p>

          <h2 className="font-bold">10. Contact Information</h2>
          <h3 className="font-bold">We’re Here to Help</h3>
          <p>
            If you have any questions or concerns regarding your international order, please feel
            free to reach out to us. Our customer service team is dedicated to providing you with
            the best possible shopping experience, no matter where you are in the world.
          </p>
          <h3 className="font-bold">Customer Service</h3>
          <p>
            Email for All Purposes: <a href="mailto:weavesfabrics@gmail.com" className="text-blue-600 underline">weavesfabrics@gmail.com</a>
          </p>
          <h3 className="font-bold">Feedback and Suggestions</h3>
          <p>
            Your feedback is important to us. If you have any suggestions on how we can improve
            our international shipping services or if you’ve had an exceptional experience, please
            don’t hesitate to share it with us. Your input helps us continue to provide the best
            possible service to our customers.
          </p>
          <h3 className="font-bold">Special Questions</h3>
          <p>
            For special questions, just drop us a mail at <a href="mailto:weavesfabrics12@gmail.com" className="text-blue-600 underline">weavesfabrics12@gmail.com</a>.
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}
